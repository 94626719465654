import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "mapbox-gl"
import bbox from "@turf/bbox"
import { multiPoint } from "@turf/helpers"
import Markers from './markers'

import "mapbox-gl/dist/mapbox-gl.css"

const MAPBOX_TOKEN = "pk.eyJ1Ijoidmlja29yb2RpIiwiYSI6ImNraTczMHYyeDA0Z2YydXA5eXp5MmR2NHYifQ.RGYKEr9Z2pe-IB6SNFsTeQ"

const places = [
  {
    name: "Avenue Building",
    longitude: 39.72147903235047,
    latitude: -4.021099687695919,
  },
]

const mapContainerStyle = {
  width: "100%",
  height: "700px",
}

const Map = () => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
      // Empire State Building [lng, lat]
      center: [39.6682, -4.0435],
      zoom: 10,
    })
    map.addControl(new mapboxgl.NavigationControl(), "top-right")
    setMap(map)
    
  }, []);

  useEffect(() => {
    if (!map) return

    if (places.length !== 0) {
      const coords = []
      places.forEach(place => {
        coords.push([place.longitude, place.latitude])
      })
      const feature = multiPoint(coords)
      const box = bbox(feature)

      map.fitBounds(
        [
          [box[0], box[1]],
          [box[2], box[3]],
        ],
        {
          padding: 20,
          maxZoom: 14,
          duration: 2000,
          center: [39.71147903235047, -4.0211],
          bearing: 25,
          
        }
      )
    } else {
      map.easeTo({
        center: [39.73147903235047, -4.021099687695919],
        zoom: 10,
        duration: 2000,
      })
    }
  }, [map])
  return (
    <div ref={mapContainerRef} style={mapContainerStyle}>
      {places && map && <Markers map={map} places={places} />}
    </div>
  )

}

export default Map